import { createSlice } from '@reduxjs/toolkit';

const CareerHubReducer = createSlice({
    name: 'CareerHubReducer',

    initialState: {
        careerHub: null
    },
  
    reducers: {
        setCareerHub: (state, action) => {
            state.careerHub = action.payload
        }
    }
})

export const CareerHubActions = CareerHubReducer.actions

export default CareerHubReducer.reducer