import { createGlobalStyle } from 'styled-components'

import KnowkoutHtf29JuniorliteweightWebfontWoff from "./assets/fonts/knockout-htf29-juniorliteweight-webfont.woff"

import GilroyThinWoff2 from "./assets/fonts/Gilroy-Thin.woff2"
import GilroyThinWoff from "./assets/fonts/Gilroy-Thin.woff"

import GilroyBoldItalicWoff2 from "./assets/fonts/Gilroy-BoldItalic.woff2"
import GilroyBoldItalicWoff from "./assets/fonts/Gilroy-BoldItalic.woff"

import GilroyMediumWoff2 from "./assets/fonts/Gilroy-Medium.woff2"
import GilroyMediumWoff from "./assets/fonts/Gilroy-Medium.woff"

import GilroyHeavyItalicWoff2 from "./assets/fonts/Gilroy-HeavyItalic.woff2"
import GilroyHeavyItalicWoff from "./assets/fonts/Gilroy-HeavyItalic.woff"

import GilroySemiBoldItalicWoff2 from "./assets/fonts/Gilroy-SemiBoldItalic.woff2"
import GilroySemiBoldItalicWoff from "./assets/fonts/Gilroy-SemiBoldItalic.woff"

import GilroyBoldWoff2 from "./assets/fonts/Gilroy-Bold.woff2"
import GilroyBoldWoff from "./assets/fonts/Gilroy-Bold.woff"

import GilroyLightWoff2 from "./assets/fonts/Gilroy-Light.woff2"
import GilroyLightWoff from "./assets/fonts/Gilroy-Light.woff"

import GilroyExtraBoldItalicWoff2 from "./assets/fonts/Gilroy-ExtraBoldItalic.woff2"
import GilroyExtraBoldItalicWoff from "./assets/fonts/Gilroy-ExtraBoldItalic.woff"

import GilroyBlackWoff2 from "./assets/fonts/Gilroy-Black.woff2"
import GilroyBlackWoff from "./assets/fonts/Gilroy-Black.woff"

import GilroyRegularItalicWoff2 from "./assets/fonts/Gilroy-RegularItalic.woff2"
import GilroyRegularItalicWoff from "./assets/fonts/Gilroy-RegularItalic.woff"

import GilroyHeavyWoff2 from "./assets/fonts/Gilroy-Heavy.woff2"
import GilroyHeavyWoff from "./assets/fonts/Gilroy-Heavy.woff"

import GilroyRegularWoff2 from "./assets/fonts/Gilroy-Regular.woff2"
import GilroyRegularWoff from "./assets/fonts/Gilroy-Regular.woff"

import GilroyMediumItalicWoff2 from "./assets/fonts/Gilroy-MediumItalic.woff2"
import GilroyMediumItalicWoff from "./assets/fonts/Gilroy-MediumItalic.woff"

import GilroyLightItalicWoff2 from "./assets/fonts/Gilroy-LightItalic.woff2"
import GilroyLightItalicWoff from "./assets/fonts/Gilroy-LightItalic.woff"

import GilroyUltraLightItalicWoff2 from "./assets/fonts/Gilroy-UltraLightItalic.woff2"
import GilroyUltraLightItalicWoff from "./assets/fonts/Gilroy-UltraLightItalic.woff"

import GilroyUltraLightWoff2 from "./assets/fonts/Gilroy-UltraLight.woff2"
import GilroyUltraLightWoff from "./assets/fonts/Gilroy-UltraLight.woff"

import GilroyExtraBoldWoff2 from "./assets/fonts/Gilroy-ExtraBold.woff2"
import GilroyExtraBoldWoff from "./assets/fonts/Gilroy-ExtraBold.woff"

import GilroySemiBoldWoff2 from "./assets/fonts/Gilroy-SemiBold.woff2"
import GilroySemiBoldWoff from "./assets/fonts/Gilroy-SemiBold.woff"

import GilroyThinItalicWoff2 from "./assets/fonts/Gilroy-ThinItalic.woff2"
import GilroyThinItalicWoff from "./assets/fonts/Gilroy-ThinItalic.woff"

import GilroyBlackItalicWoff2 from "./assets/fonts/Gilroy-BlackItalic.woff2"
import GilroyBlackItalicWoff from "./assets/fonts/Gilroy-BlackItalic.woff"

const FontStyles = createGlobalStyle`
    @font-face {
        font-family: 'Knockout';
        font-display: swap;
        src: url(${KnowkoutHtf29JuniorliteweightWebfontWoff}) format("woff");
        font-weight: 400;
        font-style: normal; 
    }
    
    @font-face {
        font-family: 'Gilroy';
        font-display: swap;
        src: url(${GilroyThinWoff2}) format("woff2"), url(${GilroyThinWoff}) format("woff");
        font-weight: 100;
        font-style: normal; 
    }
    
    @font-face {
        font-family: 'Gilroy';
        font-display: swap;
        src: url(${GilroyBoldItalicWoff2}) format("woff2"), url(${GilroyBoldItalicWoff}) format("woff");
        font-weight: bold;
        font-style: italic; 
    }
    
    @font-face {
        font-family: 'Gilroy';
        font-display: swap;
        src: url(${GilroyMediumWoff2}) format("woff2"), url(${GilroyMediumWoff}) format("woff");
        font-weight: 500;
        font-style: normal; 
    }
    
    @font-face {
        font-family: 'Gilroy';
        font-display: swap;
        src: url(${GilroyHeavyItalicWoff2}) format("woff2"), url(${GilroyHeavyItalicWoff}) format("woff");
        font-weight: 900;
        font-style: italic; 
    }
    
    @font-face {
        font-family: 'Gilroy';
        font-display: swap;
        src: url(${GilroySemiBoldItalicWoff2}) format("woff2"), url(${GilroySemiBoldItalicWoff}) format("woff");
        font-weight: 600;
        font-style: italic; 
    }
    
    @font-face {
        font-family: 'Gilroy';
        font-display: swap;
        src: url(${GilroyBoldWoff2}) format("woff2"), url(${GilroyBoldWoff}) format("woff");
        font-weight: bold;
        font-style: normal; 
    }
    
    @font-face {
        font-family: 'Gilroy';
        font-display: swap;
        src: url(${GilroyLightWoff2}) format("woff2"), url(${GilroyLightWoff}) format("woff");
        font-weight: 300;
        font-style: normal; 
    }
    
    @font-face {
        font-family: 'Gilroy';
        font-display: swap;
        src: url(${GilroyExtraBoldItalicWoff2}) format("woff2"), url(${GilroyExtraBoldItalicWoff}) format("woff");
        font-weight: 800;
        font-style: italic; 
    }
    
    @font-face {
        font-family: 'Gilroy';
        font-display: swap;
        src: url(${GilroyBlackWoff2}) format("woff2"), url(${GilroyBlackWoff}) format("woff");
        font-weight: 900;
        font-style: normal; 
    }
    
    @font-face {
        font-family: 'Gilroy';
        font-display: swap;
        src: url(${GilroyRegularItalicWoff2}) format("woff2"), url(${GilroyRegularItalicWoff}) format("woff");
        font-weight: normal;
        font-style: italic; 
    }
    
    @font-face {
        font-family: 'Gilroy';
        font-display: swap;
        src: url(${GilroyHeavyWoff2}) format("woff2"), url(${GilroyHeavyWoff}) format("woff");
        font-weight: 900;
        font-style: normal; 
    }
    
    @font-face {
        font-family: 'Gilroy';
        font-display: swap;
        src: url(${GilroyRegularWoff2}) format("woff2"), url(${GilroyRegularWoff}) format("woff");
        font-weight: normal;
        font-style: normal; 
    }
    
    @font-face {
        font-family: 'Gilroy';
        font-display: swap;
        src: url(${GilroyMediumItalicWoff2}) format("woff2"), url(${GilroyMediumItalicWoff}) format("woff");
        font-weight: 500;
        font-style: italic; 
    }
    
    @font-face {
        font-family: 'Gilroy';
        font-display: swap;
        src: url(${GilroyLightItalicWoff2}) format("woff2"), url(${GilroyLightItalicWoff}) format("woff");
        font-weight: 300;
        font-style: italic; 
    }
    
    @font-face {
        font-family: 'Gilroy';
        font-display: swap;
        src: url(${GilroyUltraLightItalicWoff2}) format("woff2"), url(${GilroyUltraLightItalicWoff}) format("woff");
        font-weight: 200;
        font-style: italic; 
    }
    
    @font-face {
        font-family: 'Gilroy';
        font-display: swap;
        src: url(${GilroyUltraLightWoff2}) format("woff2"), url(${GilroyUltraLightWoff}) format("woff");
        font-weight: 200;
        font-style: normal; 
    }
    
    @font-face {
        font-family: 'Gilroy';
        font-display: swap;
        src: url(${GilroyExtraBoldWoff2}) format("woff2"), url(${GilroyExtraBoldWoff}) format("woff");
        font-weight: 800;
        font-style: normal; 
    }
    
    @font-face {
        font-family: 'Gilroy';
        font-display: swap;
        src: url(${GilroySemiBoldWoff2}) format("woff2"), url(${GilroySemiBoldWoff}) format("woff");
        font-weight: 600;
        font-style: normal; 
    }
    
    @font-face {
        font-family: 'Gilroy';
        font-display: swap;
        src: url(${GilroyThinItalicWoff2}) format("woff2"), url(${GilroyThinItalicWoff}) format("woff");
        font-weight: 100;
        font-style: italic; 
    }
    
    @font-face {
        font-family: 'Gilroy';
        font-display: swap;
        src: url(${GilroyBlackItalicWoff2}) format("woff2"), url(${GilroyBlackItalicWoff}) format("woff");
        font-weight: 900;
        font-style: italic; 
    }
`

export default FontStyles