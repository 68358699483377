import React, { Component } from 'react';

class Loader extends Component {

    render() {

        return (
            <div></div>
        );

    }

}

export default Loader;
