import { createSlice } from '@reduxjs/toolkit';

const ReadabilityReducer = createSlice({
    name: 'ReadabilityReducer',

    initialState: {
        readability: null
    },
  
    reducers: {
        setReadability: (state, action) => {
            state.readability = action.payload
        }
    }
})

export const ReadabilityActions = ReadabilityReducer.actions

export default ReadabilityReducer.reducer