/* eslint-disable */
import React, { PureComponent } from 'react';
import { Button, Input, Label } from 'reactstrap';
import Server from '../Server';
import './Input.css';
import './LoginFormGroup.css';
import { Modal } from "reactstrap";
import Cookies from "js-cookie";
import './PreProductionAccessPopup.css'

let server = new Server();

class PreProductionAccessPopup extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {  
            passwordL: "",
            emailL: "",
            isLogin: false,
            loginStatusMsg: "",
            modal: false,
        };
    }

    componentDidMount(){
        console.log('location.hostname', location.hostname)
        const isPrePorductionAdmin = Cookies.get("isPrePorductionAdmin");
        const isPrePorductionServer = location.hostname 
        this.setState({modal: ((isPrePorductionAdmin && isPrePorductionAdmin === "true") || (isPrePorductionServer !== 'gradbay-pre.hestawork.com')) ? false : true})
      }

    disableButton = () => { this.setState({ buttonEnabled: false }) }
    enableButton = () => { this.setState({ buttonEnabled: true }) }

    onInputChange = (e) => {
        let modifiedField = e.target.id;
        let modifiedFieldValue = e.target.value;
        let state = {...this.state};
        state[modifiedField] = modifiedFieldValue;
        this.setState(state);
    }

    login = (e) => {
        this.disableButton();
        server.api({
            method: "post",
            url: "/api/user/pre-production-access",
            data: {
                user: {
                    email: this.state.emailL,
                    password: this.state.passwordL
                }
            },
            then: function(res) {
                this.enableButton();
                if (res.data.success === true) {
                    var date = new Date();
                    var minutes = 300;
                    date.setTime(date.getTime() + minutes * 60 * 1000);
                    Cookies.set("isPrePorductionAdmin", true, { expires: date });
                    this.setState({loginStatusMsg: "", modal: false})
                } else {
                    this.setState({
                        loginStatusMsg: (res.data.error || "Unexpected Error")
                    })
                }
            }.bind(this),
            catch: function(e) {
                this.enableButton();
                if (e.response.status === 429) {
                    this.setState({ loginStatusMsg: "Too many login attempts, please try again in 10 mins" })
                } else {
                    this.setState({ loginStatusMsg: "Unexpected Error" })  
                } 
            }.bind(this)
        })
    }

    setLoginError = (message) => {
        this.setState({
            loginStatusMsg: message
        })
    }

    onClose = () => {
        this.setState({modal: false})
    }

    render() {
        return (
            <div className="pre_production_model">
                <Modal
                    isOpen={this.state.modal}
                    className="welcome_popup_container"
                >
                    <div className="login-container">
                        <div className="login-form-wrap">
                            <div className="container">
                                <div className="login">
                                    <div className="sections">
                                        <section data-section="signup" className="login-form signup-form">
                                            <h2>Pre Production Admin</h2>
                                            <h5 className='mb-5'>Only, admin can access pre production server</h5>
                                            <div className="form-group-wrap d-flex">
                                                <div className="form-group left">
                                                    <Label for="emailL">Email Address</Label>
                                                    <Input onBlur={this.onInputChange} defaultValue={this.state.emailL} type="email" name="emailL" id="emailL"  />
                                                </div>
                                                <div className="form-group right">
                                                </div>
                                            </div>

                                            <div className="form-group-wrap d-flex mb-3">
                                                <div className="form-group left">
                                                    <Label for="passwordL">Password</Label>
                                                    <Input onBlur={this.onInputChange} defaultValue={this.state.passwordL} type="password" name="passwordL" id="passwordL"  />
                                                </div>
                                                <div className="form-group right">
                                                </div>
                                            </div>
                                            <div className="custom-radio-field"> 
                                                <div />
                                                <div className="bottom_action_navbar">
                                                    <Button data-enabled={this.state.buttonEnabled} className="submit-btn" onClick={ (e) => { this.login(e) } }>Login</Button>
                                                </div>
                                            </div>
                                            <div className="status-msg">
                                                {this.state.loginStatusMsg}
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>    
        )
    }
}

export default PreProductionAccessPopup;