import { useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAuth from "v2/hooks/useAuth";
import { RxRocket } from "react-icons/rx";
import Cookies from "js-cookie";
import { Alert } from "reactstrap";

const ProfileBooter = (props) => {

    const progress = useSelector(state => state.auth.progress_progress)
    const { setProfileProgress } = useAuth()
    const [visible, setVisible ] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            const user = JSON.parse(localStorage.getItem("user"));
            if (user) setProfileProgress({ id: user?.bullhornId })
        }, 100)
    }, [])

    const closeProfileBooster = () => {
        var now = new Date();
        var expire = new Date();
        expire.setFullYear(now.getFullYear());
        expire.setMonth(now.getMonth());
        expire.setDate(now.getDate() + 1);
        expire.setHours(0);
        expire.setMinutes(0);
        expire.setSeconds(0);

        Cookies.set("isProfileBoosterClose", true, { expires: expire });
        setVisible(false)
        document.querySelector('body').classList.remove("booster_strip");
    }

    let isProfileBooster = Cookies.get('isProfileBoosterClose')

    const findObj = _.find(progress?.progress, { completed: false })

    if ((progress?.completeness === 100 && findObj === undefined) || isProfileBooster === "true") return null
    document.querySelector('body').classList.add("booster_strip");
    return (

        <Alert className="boost_profile_wrap" isOpen={visible} toggle={closeProfileBooster}>
            <span><RxRocket size={18}/> Boost your profile <b>{progress?.completeness}%</b> - Add {findObj?.name} to increase engagement from employers.</span>
            {findObj?.link && <Link className="add_cta_boost" to={findObj?.link}> Add {findObj?.name}</Link>}
        </Alert>
    );
}

export default ProfileBooter