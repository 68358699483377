/* eslint-disable */
import SettingsIcon from '-!svg-react-loader!../icons/settings.svg';
import React from 'react';
import { Link } from "react-router-dom";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import './Dropdown.css';


export default class DropdownComponent extends React.Component {

    constructor(props) {

        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false
        };

    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    onClick = (e) => {
        this.props.onChange(this.props.id, e.currentTarget.innerHTML);
    }

    render() {

        let classNames = "";

        if ( (this.props.light !== undefined) && (this.props.light) ) {
            classNames += " white"
        }

        return (
            <Dropdown id={this.props.id} className={classNames + " title" + (this.props.title) } isOpen={this.state.dropdownOpen} toggle={this.toggle} data-style={this.props.theme}>
                { (this.props.icon === "alt") ?
                    <DropdownToggle className="alt" style={{ border: 'none' }}>
                        <SettingsIcon style={{ width: 14, height: 14, fill: 'white', top: -2, right: -3, position: 'relative', cursor: 'pointer' }} />
                    </DropdownToggle>
                    :
                    <DropdownToggle caret>
                        {this.props.title}
                    </DropdownToggle>
                }
                <DropdownMenu>
                    {
                        this.props.items.map(item => {
                            if (!item)
                                return null;
                            if (item.link) {
                                return (
                                    <Link
                                        style={{padding: '8px 24px !important'}}
                                        className="dropdown-item"
                                        key={item.title}
                                        onClick={() => {
                                            if (this.props.onClickCb) {
                                                this.props.onClickCb();
                                                this.toggle();
                                            }
                                        }}
                                        to={item.link}>
                                        
                                            {item.data}

                                        </Link>
                                )
                            }
                            if (item.onClick) {
                                return <DropdownItem key={item.title} onClick={() => {
                                    item.onClick();
                                    this.toggle();
                                    if (this.props.onClickCb) {
                                        this.props.onClickCb();
                                        this.toggle();
                                    }
                                }}>{item.title}</DropdownItem>
                            }
                            return <DropdownItem key={item.title} onClick={() => {
                                this.props.onClick(item.data)
                                this.toggle();
                                if (this.props.onClickCb) {
                                    this.props.onClickCb();
                                    this.toggle();
                                }
                            }}>{item.title}</DropdownItem>
                        })
                    }
                </DropdownMenu>
            </Dropdown>
        );

    }

}
