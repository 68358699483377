import { createGlobalStyle } from "styled-components";

const Styles = createGlobalStyle`
	.toasts-container .toast toast-error, 
	.toasts-container .undefined, 
	.toasts-container .sc-bwzfXH, 
	.toasts-container .hBChTE {
		max-height: 60px;
		color: #fff; 
	}
  	html {
		overflow-x: hidden; 
	}
  	body {
			margin: 0;
			padding: 0;
			overflow-x: hidden; 
			&.modal-open {
				overflow: hidden!important;
				// position: fixed;
				// width: 100vw;
				// height: 100vh;
			}
	}
  	// * {
	// 	font-family: 'Montserrat', sans-serif;
	// 	font-display: swap; 
	// }
  
  	ul {
		-webkit-margin-before: 0;
		-webkit-margin-after: 0;
		margin-bottom: 0;
		padding: 0; 
	}
  	button {
		cursor: pointer; 
	}
  	select {
		-webkit-appearance: none; 
	}
  
  	h1, h2, h3, h4, h5, h6 {
		font-weight: 700; 
	}
  
  	*:focus {
		outline: 0 !important; 
	}
  
  	.form-control:focus {
		border-color: inherit !important;
		box-shadow: none !important; 
	}
  
  	.modal-open {
		overflow: hidden !important; 
	}
  
  	.modal-backdrop {
		opacity: 0; 
	}
  
  	.hide {
		display: none !important; 
	}
  
	.main-container {
		max-width: 1020px;
		width: 100%;
		margin: auto;
		padding-right: 40px;
		padding-left: 40px; }
		@media (max-width: 540px) {
		.main-container {
			padding-right: 20px;
			padding-left: 20px; 
		} 
	}
  
  	.main-container-wide {
		max-width: 1200px; 
	}
  
  	.main-btn {
		display: block;
		text-align: center;
		width: 200px;
		margin: auto;
		background-color: #45F6A6;
		color: black;
		padding: 6px 28px;
		font-weight: 500;
		border-radius: 52px;
		border: none; 
	}
  
  	.cookieConsent {
		width: calc(100% - 40px) !important;
		left: 20px !important;
		bottom: 18px !important;
		border-radius: 52px !important; 
	}
	.cookieConsent div {
	  	margin-left: 20px !important; 
	}
	.cookieConsent button {
		background-color: #45F6A6 !important;
		margin: 10px !important;
		border-radius: 30px !important;
		height: 100% !important;
		padding: 15px 24px !important;
		display: inline-block !important;
		color: black !important; 
	}

	@media (max-width: 700px) {
	  	.cookieConsent {
			display: block !important;
			border-radius: 12px !important; 
		}
		.cookieConsent button {
			width: 100%;
			border-radius: 0 0 12px 12px !important; 
		} 
	}
  
  	button.underline-on-hover:hover, a.underline-on-hover:hover {
		text-decoration: underline; 
	}
  
  	.toasts-container {
		transform: none;
		left: unset !important;
		right: 10px !important;
		transform: none !important;
		flex-direction: row !important;
		color: #fff;
		font-weight: 600;
		border: none !important; 
	}
	.toasts-container > div {
	  	box-shadow: none !important;
	  	border: none !important; 
	}
`;

export default Styles;
