//import "babel-polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './IE.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';

import * as serviceWorkerRegistration from "./serviceWorkerRegistration.js";

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorkerRegistration.register({
    onUpdate: (registration) => {
      // Perform actions when the service worker is updated
      console.log('Service worker updated');
      // Additional logic...
    },
    onSuccess: (registration) => {
      // Perform actions when the service worker is successfully installed
      console.log('Service worker installed');
      // Additional logic...
    },
  });
