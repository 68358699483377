import { createSlice } from '@reduxjs/toolkit';

const AuthReducer = createSlice({
    name: 'AuthReducer',

    initialState: {
        user: null,
        progress_progress: null,
        company_details: null
    },
  
    reducers: {
        setAuth: (state, action) => {
            state.user = action.payload
            state.company_details = null
        },
        logout: (state, action) => {
            state.user = null
        },
        setProfileProgress: (state, action) => {
            state.progress_progress = {
                ...state.progress_progress,
                ...action.payload,
            }
        },
        setCompanyProfileDetails: (state, action) => {
            state.company_details = action.payload
        },
    }
})

export const AuthActions = AuthReducer.actions

export default AuthReducer.reducer