import { createSlice ,current} from '@reduxjs/toolkit';
import _ from 'lodash';

const SearchReducer = createSlice({
    name: 'SearchReducer',

    initialState: {
        job_search_params: null,
        grad_search_params: null,
        sonic_job_categories: null,
        sonic_job_cities: null,
        hot_job_categories: null,
        hot_job_cities: null,
    },
  
    reducers: {
        setJobSearchParams: (state, action) => {
            const currentState = state.job_search_params;
    
            let salaries = [];
            if (action.payload?.salary) {
                action.payload.salary.forEach(item => {
                    let salary = item.id.split("-");
                    salaries.push(...salary);
                });
            }
            const salary_range = {
                min: parseInt(_.min(salaries)) * 1000,
                max: parseInt(_.max(salaries)) * 1000,
            };
            const updateData = {
                ...action.payload,
                salary_range,
            };
            return {
                ...state,
                job_search_params: {
                    ...currentState, 
                    ...updateData,   
                },
            };
        },
        setGradSearchParams: (state, action) => {
            state.grad_search_params = {
                ...state.grad_search_params,
                ...action.payload
            }
        },
        clearFilterJob: (state, action)=>{
              state.job_search_params = null
        },
        clearFilterGrad: (state, action)=>{
            state.grad_search_params = null
        },
        setSonicJobCategories: (state, action) => {
            const categories = JSON.parse(JSON.stringify(state.sonic_job_categories?.categories || []));
            const updatedCat = [...categories, ...action.payload.categories];
            state.sonic_job_categories = {
              categories: _.uniqBy(updatedCat, 'text'),
              pages: parseInt(action.payload.pages)
            };
          },
        setSonicJobCities: (state, action)=>{
            const cities = JSON.parse(JSON.stringify(state.sonic_job_cities?.cities || []));
            const updatedCity = [...cities, ...action.payload.cities];
            state.sonic_job_cities = {
                cities: updatedCity,
                pages: parseInt(action.payload.pages)
            }
        },
        setHotJobCategories: (state, action) => {
            const categories = JSON.parse(JSON.stringify(state.hot_job_categories?.categories || []));
            const updatedCat = [...categories, ...action.payload.categories];
            state.hot_job_categories = {
              categories: _.uniqBy(updatedCat, 'text'),
              pages: parseInt(action.payload.pages)
            };
        },
        setHotJobCities: (state, action)=>{
            const cities = JSON.parse(JSON.stringify(state.hot_job_cities?.cities || []));
            const updatedCity = [...cities, ...action.payload.cities];
            state.hot_job_cities = {
                cities: updatedCity,
                pages: parseInt(action.payload.pages)
            }
        },

        clearAllCatAndCity: (state, action) => {
            state.sonic_job_categories = null
            state.sonic_job_cities = null
        },

        clearAllHotCatAndCity: (state, action) => {
            state.hot_job_categories = null
            state.hot_job_cities = null
        }
    }
})

export const SearchActions = SearchReducer.actions

export default SearchReducer.reducer