import { createSlice } from '@reduxjs/toolkit';
import { groupBy } from 'v2/Utilities'

const TagsReducer = createSlice({
    name: 'TagsReducer',

    initialState: {
        tags: null,
        job_type_and_sectors: []
    },
  
    reducers: {
        setTags: (state, action) => {
            state.tags = {
                ...state.tags,
                ...action.payload
            }
        },
        setJobTypeAndSectorTags: (state, action) => {
            state.job_type_and_sectors = groupBy(action.payload)
        }
    }
})

export const TagsActions = TagsReducer.actions

export default TagsReducer.reducer