import { useDispatch } from 'react-redux'
import { apiRequest, formSubmitStart, formSubmitSuccess, formSubmitError, loginOrSignupSuccess, forwardTo, getProfileProgress} from 'v2/Utilities'
import { AuthActions } from 'v2/reducers/AuthReducer'
import { useLocation } from 'react-router-dom';
import { post } from 'axios';
import keys from 'config/keys';
import { reset } from 'redux-form';
import { ToastStore } from 'react-toasts';

const createFile = (url, callback) => {
    return new Promise(async (resolve, reject) => {
        if(!url) reject('URL not found')
        const params = new URLSearchParams(url)
        let filename = params.get('t')
        let response = await fetch(url);
        let data = await response.blob();
        let metadata = {type: 'image/jpeg'};
        let file = new File([data], `${filename}.jpeg`, metadata);
        resolve(file)
    })
}

const uploadProfilePic = async (profilePic, userId, bullhornId, token) => {
    let file = await createFile(profilePic)
	let url = `${keys.domainUploadMS}api/upload/profile-picture/${userId}/${bullhornId}`
	const formData = new FormData();
	formData.append('profile-picture', file, 'profile-picture')
	const config = {
		headers: {
			'content-type': 'multipart/form-data',
			'Authorization': "Token " + token
		}
	}
	return  post(url, formData, config)
}

const useAuth = (data) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const login = async (payload, free_job_post) => {
        try {
            formSubmitStart('login')
            const response = await apiRequest({
                method: 'POST',
                url: `v2/user/login`,
                data: payload
            })
            loginOrSignupSuccess('login', response.data, location, free_job_post)
        } catch (error) {
            formSubmitError('login', error)
        }
    }

    const signup = async (payload, free_job_post) => {
        try {
            formSubmitStart('signup')
            const response = await apiRequest({
                method: 'POST',
                url: `v2/user/signup`,
                data: payload
            })
            if(payload?.user?.profilePic && payload?.user?.type === 'Graduate'){
                await uploadProfilePic(payload?.user?.profilePic, response?.data?.data?.user?._id, response?.data?.data?.user?.bullhornId, response?.data?.data?.user?.token)
            }
            loginOrSignupSuccess('signup', response.data, undefined, free_job_post)
        } catch (error) {
            formSubmitError('signup', error)
        }
    }

    const forgotPassword = async (payload) => {
        try {
            formSubmitStart('forgot_password')
            const response = await apiRequest({
                method: 'POST',
                url: `v2/user/restore/email`,
                data: payload
            })
            formSubmitSuccess('forgot_password', response.data.message)
        } catch (error) {
            formSubmitError('forgot_password', error)
        }
    }

    const resetPassword = async (payload) => {
        try {
            formSubmitStart('reset_password')
            const response = await apiRequest({
                method: 'POST',
                url: `v2/user/restore/password`,
                data: payload
            })
            formSubmitSuccess('reset_password', response.data.message)
        } catch (error) {
            formSubmitError('reset_password', error)
        }
    }

    const logout = () => {
        dispatch(AuthActions.logout())
        forwardTo('/')
    }

    const setProfileProgress = async (payload) => {
        try {
            const response = await apiRequest({
                method: 'POST',
                url: `graduate/get-by-id/extended`,
                data: payload
            })
            if(response.data.success){
                dispatch(AuthActions.setProfileProgress(getProfileProgress(response.data.data.data[0])))
            }
        } catch (error) {
            console.log("RESPONSE ERROR ===>>>", error)
        }
    }

    const getCompanyProfileDetails = async (payload) => {
        try {
            const response = await apiRequest({
                method: 'GET',
                url: `user/company-profile-details/${payload.token}`,
            })
            if(response.data.success){
                dispatch(AuthActions.setCompanyProfileDetails(response.data.data))
            }
        } catch (error) {
            ToastStore.error(error.response?.data?.message || 'Something went wrong. Please try again later.')
            setTimeout(() => {
                window.location.href = '/login'
            }, 2000);
        }
    }

    const completeCompanyProfileDetails = async (payload) => {
        try {
            const response = await apiRequest({
                method: 'POST',
                url: `user/company-profile-complete`,
                data: payload
            })
            if(response.data.success){
                localStorage.setItem('authToken', response?.data?.data?.user?.token);
                localStorage.setItem("user", JSON.stringify(response?.data?.data?.user));
                dispatch(AuthActions.setAuth(response?.data?.data?.user))
                dispatch(reset('company_profile_completion'))
                ToastStore.success(response?.data?.data?.message || 'Success')
                setTimeout(() => {
                    window.location.href = "/my-profile/business"
                }, 1000)
            }
        } catch (error) {
            formSubmitError('company_profile_completion', error)
        }
    }

    return {
        login,
        signup,
        forgotPassword,
        resetPassword,
        logout,
        setProfileProgress,
        getCompanyProfileDetails,
        completeCompanyProfileDetails
    }
};

export default useAuth;
