import React, { Component } from 'react';
import './AppLoader.css';
import AppSpinner from './AppSpinner';


class AppLoader extends Component {

    render() {

        return (
            <div className="full-screen-loader">
                <AppSpinner show={true} />
            </div>
        );

    }

}

export default AppLoader;
