import { Component } from 'react';
import axios from 'axios';

class Server extends Component {

    authenticationToken: null

    constructor(props) {

        super(props)

        var token = this.getAuthenticationTokenFromLocalStorage();
        setTimeout(function() {
            if (window.globalVars !== undefined) {
                window.globalVars.authenticationToken = token;
            }
        }, 500)

        if ( (token !== null) && (token !== undefined) && (token !== "") ) {
            this.authenticationToken = token;
        }

    }

    componentDidMount() {

    }

    logout() {
        // var myItem = localStorage.getItem('key');
        localStorage.clear();
        // localStorage.setItem('key',myItem);
        //localStorage.clear();
        window.location.href = "/";
    }

    api(request) {

        let full = window.location.protocol+'//'+window.location.hostname+(window.location.port ? ':'+window.location.port: '');
        axios.defaults.baseURL = full;

        let headers = {

        }

        // if (Utilities.isENU())(request.url === "api/user/current") {
        //     return;
        // }

        if (request.url !== "/api/user/login") {
            let token = this.authenticationToken || localStorage.getItem('authToken')
            headers['Authorization'] = "Token " + token
        }

        axios({
            method: request.method,
            url: request.url,
            data: request.data,
            headers: request?.headers || headers,
        }).then(function (response) {
            request.then(response);
        }.bind(this))
        .catch(function (error) {
            console.log('ERROR API', error)
            if ( (error.response === undefined) || (error.response.status !== 401) ) {
                request.catch(error);
            } else {

                if (!request.ignoreUnauthorized) {

                    localStorage.clear();
                    window.location.href = "/login"
                    // if ( (this.authenticationToken !== null) && (this.authenticationToken !== undefined) ) {
                    //     window.location.href = "/401"
                    // } else {
                    //     window.location.href = "/login"
                    // }

                }

            }
        }.bind(this));

    }

    getAuthenticationTokenFromLocalStorage = () => {
        return localStorage.getItem('authToken');
    }

    saveAuthenticationTokenToLocalStorage = (token) => {
        localStorage.setItem('authToken', token);
        this.authenticationToken = token;
    }

}

export default Server;
