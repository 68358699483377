class Permissions {

    isLoggedIn = () => {

        if ( (window.globalVars.user === undefined) || (window.globalVars.user === null) ) {
            return false;
        } else {
            return true;
        }

    }

    isAllowed = (permission) => {

        // Allow some actions to logged out users, they cannot do them, these will prompt them to sign up
        if ( (window.globalVars.user === undefined) || (window.globalVars.user === null) ) {

            switch (permission) {

                case "CANDIDATE_WISHLIST":
                case "CAN_APPLY":
                case "JOB_WISHLIST":
                case "SHOW_PRICING":
                    return true;
                    break;

                // Everything else is denied for logged out users
                default:
                    return false;
                    break;

            }

        }

        let userType = window.globalVars.user.type;

        // Logged in state
        switch (permission) {

            // Can have access to one of the wishlists
            case "VIEW_WISHLIST":
            case "DELETE_OWN_ACCOUNT":
            case "READ_MESSAGES":
                if ( (userType === 3) || (userType === 4) ) {
                    return true
                }
                break;

            // Can apply for jobs
            case "CAN_APPLY":
            // Can read own private profile
            case "READ_OWN_PRIVATE_PROFILE":
            // Can edit own job wishlist
            case "JOB_WISHLIST":
            // Can toggle the visibility of own videos (after it has been approved)
            case "TOOGLE_VIDEO_USER_STATUS":
            case "CHANGE_OWN_STATUS":
                if (userType === 4) {
                    return true;
                }
                break;

            // Can read own private business profile
            case "READ_OWN_BUSINESS_PROFILE":
            // Can edit own candidate wishlist
            case "CANDIDATE_WISHLIST":
            case "COMPOSE_MESSAGES":
            case "REQUEST_VIDEO_ANSWER":
            case "SHOW_PRICING":
                if (userType === 3) {
                    return true;
                }
                break;

            // Can update a graduate's status
            case "CHANGE_STATUS":
            // Can read all private profiles
            case "READ_PRIVATE_PROFILE":
            // Can approve any profile pictures
            case "APPROVE_PROFILE_PICTURE":
            // Can approve any videos
            case "APPROVE_VIDEO":
            // Can remove any videos
            case "CREATE_BLOG_POST":
            case "UPDATE_BLOG_POST":
            case "ADMIN_DASHBOARD":
            case "DELETE_BLOG_POST":
                if ( (userType === 2) || (userType === 1) ) {
                    return true
                }
                break;

            case "REMOVE_VIDEO":
                if ( (userType === 2) || (userType === 1) || (userType === 4) ) {
                    return true
                }
                break;

            default:
                return false

        }

        // Deny everything else
        return false;

    }

}

export default Permissions;
