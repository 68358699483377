import { createSlice } from '@reduxjs/toolkit';

const GradsReducer = createSlice({
    name: 'GradsReducer',

    initialState: {
        grads: null
    },
  
    reducers: {
        setGrads: (state, action) => {
            state.grads = action.payload
        },

        setGradsCv: (state, action) => {
            state.cvs = action.payload
        }
    }
})

export const GradsActions = GradsReducer.actions

export default GradsReducer.reducer